import React from 'react'

export default class Sidenav extends React.Component {
  constructor(props) {
    super(props)

    this.logout = this.logout.bind(this)
  }

  logout() {
    if (typeof window !== 'undefined') {
      localStorage.clear()
      window.location.href = '/login'
    }
  }
  render() {
    return (
      <div className="sidenav">
        {/*  <a href="#">Dashboard</a>
      <a href="#">Experts</a>*/}
        <a href="/homepageeditor" style={{ fontSize: '18px' }}><i className="fa fa-home" /> Homepage</a>
        <a href="/pageeditor" style={{ fontSize: '18px' }}><i className="fa fa-address-book" /> Pages</a>
        <a href="/blogeditor" style={{ fontSize: '18px' }}><i className="fa fa-book" /> Blogs</a>
        <a href="/users" style={{ fontSize: '18px' }}><i className="fa fa-user" /> Producers</a>
        <a href="/dashboard" style={{ fontSize: '18px' }}><i className="fa fa-users" /> Experts</a>
        <a href="#" onClick={this.logout} style={{ fontSize: '18px' }}>
          <i className="fa fa-sign-out-alt" /> Logout
        </a>
      </div>
    )
  }
}
