import React from "react";

import axios from "axios";
// import "simple-react-notifications/dist/index.css";
import NotificationPopup from "../NotificationPopup";


let apiURL = "https://api.globelynx.com/api/"

export default class UserDashboardBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notification: false,
      notificationmsg: "",
      notificationstatus: "success",
      availableUsers: [],
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      _id: "",
      tobedelete: 0
    };

    this.deleteUser = this.deleteUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.editUser = this.editUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleRegisterSubmit = this.handleRegisterSubmit.bind(this);

    this.showAdduserModal = this.showAdduserModal.bind(this);
    this.closeModaladd = this.closeModaladd.bind(this);
    this.showNotification = this.showNotification.bind(this);

    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  closeDeleteModal() {
    var modal = document.querySelector("#deleteconfirmation");
    modal.style.display = "none";
  }

  showNotification() {
    this.setState(prevState => ({
      notification: !prevState.notification
    }));

    setTimeout(
      function () {
        this.setState({ notification: false });
      }.bind(this),
      2000
    );
  }

  closeModaladd() {
    var modal = document.querySelector("#addUserModal");
    modal.style.display = "none";
  }

  handleRegisterSubmit = e => {
    e.preventDefault();
    let data = {
      email: this.state.register_username,
      password: this.state.register_password,
      firstName: this.state.register_firstName,
      lastName: this.state.register_lastName
    };

    if (this.state.register_password == this.state.register_confirmpassword) {
      axios
        .post(
          apiURL + "auth/registeradmin",
          data
        )
        .then(res => {
          this.showNotification();
          this.setState({
            notificationmsg: "New user has been created.",
            notificationstatus: "success"
          });

          window.location.reload();
        })
        .catch(error => {
          this.showNotification();
          this.setState({
            notificationmsg: "Something went wrong.",
            notificationstatus: "error"
          });
        });
    } else {
      this.showNotification();
      this.setState({
        notificationmsg: "Password did not match.",
        notificationstatus: "error"
      });
    }
  };

  showAdduserModal() {
    var modal = document.querySelector("#addUserModal");
    modal.style.display = "block";
  }

  closeModal() {
    var modal = document.querySelector("#userModal");
    modal.style.display = "none";
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  editUser(val) {
    var modal = document.querySelector("#userModal");
    modal.style.display = "block";
    this.setState({
      _id: val._id,
      firstName: val.firstName,
      lastName: val.lastName,
      email: val.email,
    });
  }

  confirmDelete() {
    axios
      .delete(
        apiURL + "users/" +
        this.state.tobedelete
      )
      .then(res => {
        this.showNotification();
        this.setState({
          notificationmsg: "Success! User has been deleted.",
          notificationstatus: "success"
        });
        window.location.reload();
      })
      .catch(err => {
        this.showNotification();
        this.setState({
          notificationmsg: "Error! Something went wrong.",
          notificationstatus: "error"
        });
      });
  }

  deleteUser(id) {
    this.setState({
      tobedelete: id
    });
    var modal = document.querySelector("#deleteconfirmation");
    modal.style.display = "block";
  }

  handleSubmit(e) {
    e.preventDefault();


    var data = {}
    if (this.state.password == '') {
      data = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
      }
    } else {
      data = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        password: this.state.password
      }
    }


    axios
      .put(
        apiURL + "users/" +
        this.state._id,
        data
      )
      .then(res => {
        this.showNotification();
        this.setState({
          notificationmsg: "Success! User has been edited.",
          notificationstatus: "success"
        });
        window.location.reload();
      })
      .catch(err => {
        this.showNotification();
        this.setState({
          notificationmsg: "Error! Something went wrong.",
          notificationstatus: "error"
        });
      });
  }

  componentDidMount() {
    axios
      .get(apiURL + "users")
      .then(res => {
        this.setState({
          availableUsers: res.data
        });
      });
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ display: this.state.notification ? "block" : "none" }}>
          <NotificationPopup status={this.state.notificationstatus}>
            {this.state.notificationmsg}
          </NotificationPopup>
        </div>

        <div className="main">
          <div className="container-fluid">
            <div className="row">
              <div className="twelve column">
                <button onClick={this.showAdduserModal}>Add Producers</button>
              </div>
              <div className="twelve column" style={{
                height: '80vh',
                overflow: 'scroll',
              }}>
                <table className="u-full-width">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.availableUsers.map((val, key) => {
                      return (
                        <tr>
                          <td>
                            {val.firstName} {val.lastName}
                          </td>
                          <td>{val.email}</td>
                          <td>
                            <button
                              className="button btn-dashboard is-dark"
                              onClick={() => this.editUser(val)}
                            >
                              <i className="fa fa-edit" />
                            </button>
                            <button
                              className="button btn-dashboard"
                              style={{ background: "#d24040" }}
                              onClick={() => this.deleteUser(val._id)}
                            >
                              <i className="fa fa-trash" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div
          id="userModal"
          className="modal-dashboard"
          style={{ display: "none" }}
        >
          <form method="post" onSubmit={this.handleSubmit}>
            <div className="modal-content-dashboard">
              <div className="modal-header-dashboard">
                <span
                  onClick={this.closeModal}
                  className="close-modal-dashboard"
                >
                  &times;
                </span>
                <h5>Edit Users</h5>
              </div>
              <div className="modal-body-dashboard">
                <div className="container-fluid">
                  <div className="row">
                    <div className="six columns">
                      <label>Firstname</label>
                      <input
                        className="u-full-width"
                        type="text"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="six columns">
                      <label>Lastname</label>
                      <input
                        className="u-full-width"
                        type="text"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="six columns">
                      <label>Email</label>
                      <input
                        disabled={true}
                        className="u-full-width"
                        type="text"
                        name="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                      />
                    </div>
                    <div className="six columns">
                      <label>New Password</label>
                      <input
                        className="u-full-width"
                        type="password"
                        name="password"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal-footer-dashboard"
                style={{ textAlign: "right", padding: "25px" }}
              >
                <button className="button btn-dashboard is-dark" type="submit">
                  <i className="fa fa-edit" /> Save
                </button>
              </div>
            </div>
          </form>
        </div>

        <div
          id="addUserModal"
          className="modal-dashboard"
          style={{ display: "none" }}
        >
          <form method="post" onSubmit={this.handleRegisterSubmit}>
            <div className="modal-content-dashboard">
              <div className="modal-header-dashboard">
                <span
                  onClick={this.closeModaladd}
                  className="close-modal-dashboard"
                >
                  &times;
                </span>
                <h5>Edit Users</h5>
              </div>
              <div className="modal-body-dashboard">
                <div className="container-fluid">
                  <div className="row">
                    <div className="six columns">
                      <label>Firstname</label>
                      <input
                        className="u-full-width"
                        required={true}
                        onChange={this.handleChange}
                        placeholder="First name"
                        type="text"
                        name="register_firstName"
                      />
                    </div>
                    <div className="six columns">
                      <label>Lastname</label>
                      <input
                        className="u-full-width"
                        required={true}
                        onChange={this.handleChange}
                        type="text"
                        placeholder="Last name"
                        name="register_lastName"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="six columns">
                      <label>Email / Username</label>
                      <input
                        className="u-full-width"
                        type="text"
                        placeholder="Email"
                        name="register_username"
                        required={true}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="six columns"></div>
                  </div>

                  <div className="row">
                    <div className="six columns">
                      <label>Password</label>
                      <input
                        className="u-full-width"
                        placeholder="Password"
                        type="password"
                        name="register_password"
                        required={true}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="six columns">
                      <label>Confirm Password</label>
                      <input
                        className="u-full-width"
                        placeholder="Confirm Password"
                        type="password"
                        name="register_confirmpassword"
                        required={true}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal-footer-dashboard"
                style={{ textAlign: "right", padding: "25px" }}
              >
                <button className="button btn-dashboard is-dark" type="submit">
                  <i className="fa fa-edit" /> Add
                </button>
              </div>
            </div>
          </form>
        </div>

        <div
          id="deleteconfirmation"
          className="modal-dashboard"
          style={{ display: "none" }}
        >
          <div
            className="modal-content-dashboard"
            style={{ maxWidth: "350px" }}
          >
            <div className="modal-header-dashboard">
              <span
                onClick={this.closeDeleteModal}
                className="close-modal-dashboard"
              >
                &times;
              </span>
              <h5>Delete</h5>
            </div>
            <div className="modal-body-dashboard">
              <div className="container-fluid">
                <h3 style={{ textAlign: "center" }}>
                  Are you sure you want to delete?
                </h3>
              </div>
            </div>

            <div
              className="modal-footer-dashboard"
              style={{ textAlign: "right", padding: "25px" }}
            >
              <button
                className="button btn-dashboard is-dark"
                onClick={this.closeDeleteModal}
              >
                No
              </button>
              <button
                className="button btn-dashboard"
                onClick={this.confirmDelete}
                style={{ background: "#d24040" }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
