import React from 'react'
import { navigate } from 'gatsby-link'

import Layout from '../../components/Layout'

import Sidenav from '../../components/dashboard/sidenav'

import '../../components/Styles/Custom.scss'
import '../../components/Styles/Dashboard.scss'

import jwt from 'jsonwebtoken'
import UserDashboardBody from '../../components/dashboard/userdashboardbody'
import axios from 'axios'


export default class Users extends React.Component {
  constructor(props) {
    super(props)
    if (typeof window !== 'undefined') {
      var now = new Date;
      var timestamp_now = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
        now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());

      // var timestamp_now = Math.floor(Date.now() / 1000);

      let token = localStorage.getItem('username');
      token = JSON.parse(token)
      if (localStorage.getItem('username') === null || timestamp_now >= token.expires) {
        navigate('/login')
      } else {
        navigate('/users')
      }
    }
  }




  render() {
    return (
      <Layout location="dashboard">
        <Sidenav />
        <UserDashboardBody />
      </Layout>
    )
  }
}
